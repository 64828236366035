<template>
    <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="item"
      :title="model && item.id ? `Atualizar webhook` : `Cadastrar webhook`"
      icon="mdi-webhook"
      v-bind:show.sync="dialog"
      maxWidth="45%"
    >
        <template v-slot:form>

            <v-row class="mt-1">
                <v-col cols="12" v-if="message">
                    <v-alert
                        outlined
                        color="warning"
                        prominent
                        border="left"
                        >
                        {{ message }}
                        </v-alert>
                </v-col>

                <SelectWebhookEvent 
                    v-model="item.webhook_event_id" 
                    :item="item.event"
                    label="Evento*" 
                    md="12"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.webhook_event_id"
                />

                <SelectIntegration 
                    v-model="item.integration_id"
                    :item="item.integration"
                    :error="errors.length > 0"
                    :errors="errors.integration_id"
                    label="Integração"
                    md="4"
                    outlined
                />

                <EmcFormTextField 
                    v-model="item.url" 
                    label="Url*" 
                    md="8"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.url"
                />
                

                <v-col cols="12">

                    <v-card
                        flat
                        tile
                        outlined
                    >
                        <v-toolbar dense outlined flat>

                        Se necessário, informe opções para serem enviadas no header:

                        <v-spacer></v-spacer>

                        <v-btn icon @click="addHeader()" small class="mr-3">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        </v-toolbar>
                        
                        <v-simple-table dense>
                            <template v-slot:default>
                            
                            <tbody>
                                <tr
                                v-for="(item, i) in item.headers" :key="i"
                                >
                                <td>
                                    <EmcFormTextField 
                                        v-model="item.key" 
                                        label="Chave*" 
                                        md="12"
                                        outlined
                                        dense
                                        :error="errors.length > 0"
                                        :error-messages="errors[`headers.${i}.key`]"
                                    />
                                </td>
                                <td>
                                    <EmcFormTextField 
                                        v-model="item.value" 
                                        label="Valor*" 
                                        md="12"
                                        outlined
                                        dense
                                        :error="errors.length > 0"
                                        :error-messages="errors[`headers.${i}.value`]"
                                    />
                                </td>
                                <td>
                                    <v-btn icon small @click="removeHeader(item)"><v-icon small color="red">mdi-delete</v-icon></v-btn>
                                </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>

                </v-col>

            </v-row>
        </template>

    </sys-register-base>
</template>

<script>
import { mapActions } from 'vuex'

import SelectWebhookEvent from './SelectWebhookEvent.vue';
import SelectIntegration from '../../integration/integration/components/SelectIntegration.vue'

export default {
    name: 'FormRegisterWebhook',
    data () {
        return {
            loading: false,
            message: null,
            errors: {},
           
        }
    },
    components: {
        SelectWebhookEvent,
        SelectIntegration
    },  
    props: {
        show: { type: Boolean, default: false },
        model: { default: null },
        title: { default: "título" }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.errors = {}
                this.message = null
                this.$emit('update:show', value)
            }
        },
        color() {
            return this.model.is_input ? `green lighten-2` : `orange lighten-2`
        },
        item: {
            get() {
                return this.model
            },
            set(value) {
                this.$emit('model', value)
            }
        }
    },
    methods: {
        ...mapActions('webhook', ['ActionWebhooksStore', 'ActionWebhooksUpdate']),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
        },
        addHeader()
        {
            this.item.headers.push({ key: null, value: null })
        },
        removeHeader(item)
        {
            const index = this.item.headers.indexOf(item)
            this.item.headers.splice(index, 1)

        },
        save(edit) {
            
            this.initVariables()

            if(edit) {
                this.ActionWebhooksUpdate(this.model)
                    .then((res) => {
                        this.message = res.message;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            } else {
                this.ActionWebhooksStore(this.model)
                    .then((res) => {
                        this.message = res.message;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            }
        }
    }
}
</script>