import { mapActions } from 'vuex'
import FormRegisterWebhook from '../components/FormRegisterWebhook.vue';
import ActionsLog from '../components//ActionsLog.vue';

export default {
    name: 'WebhookIndex',
    data () {
        return {
            headers: [
                { text: 'Evento', value: 'event.name', sortable: false},
                { text: 'URL', value: 'url', sortable: false},
                { text: 'Ações', value: 'actions', sortable: false}
            ],
            params: {},
            meta: {},
            collections: [],
            itemDelete: {},
            itemSelected: {},
            model: { headers: [] },
            loading: false,
            showForm: false,
            show: false,
            text: null,
            deleting: false,
            message: null,
            error: false,
            showActionsLog: false
        }
    },
    components: {
        FormRegisterWebhook,
        ActionsLog
    },
    methods: {
        ...mapActions('webhook', ['ActionWebhooksIndex', 'ActionWebhooksDestroy']),
        getData() {

            this.showForm = false;
            this.loading = !this.loading

            const params = {
                ...this.params,
                with: 'event,headers,integration'
            }

            this.ActionWebhooksIndex(params)
                .then((r) => {
                    this.collections = r.data
                    this.meta = r.meta
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showActions(item)
        {
            this.itemSelected = item;
            this.showActionsLog = true;
        },
        deleteItem() {
            this.deleting = true;
            this.message = null;

            this.ActionWebhooksDestroy({ id: this.itemDelete.id })
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                    this.message = error.message;
                    this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        }
    },
    watch: {
        params(params_new, params_old){
            if(params_new != params_old) {
                this.getData()
            }
        },
        model(item_new, item_old){
            if(item_new !== item_old && (this.model && this.model.id))
            {
                this.showForm = true
                this.message = null

            } else if(Object.keys(this.model).length === 0){
                this.showForm = false;
            }
        },
        itemDelete(item_new, item_old){
            if(item_new !== item_old && Object.keys(item_new).length > 0)
            {
                this.show = true
                this.text = "Deseja excluir o item?"
            } else {
                this.show = false
                this.text = null
            }
        },
        showForm(val) {
            if(!val) {
                this.model = { headers: [] }
            }
        },
    }
}