var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("sys-register-base", {
    attrs: {
      loading: _vm.loading,
      model: _vm.item,
      title:
        _vm.model && _vm.item.id ? "Atualizar webhook" : "Cadastrar webhook",
      icon: "mdi-webhook",
      show: _vm.dialog,
      maxWidth: "45%"
    },
    on: {
      onSave: _vm.save,
      "update:model": function($event) {
        _vm.item = $event
      },
      "update:show": function($event) {
        _vm.dialog = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "form",
        fn: function() {
          return [
            _c(
              "v-row",
              { staticClass: "mt-1" },
              [
                _vm.message
                  ? _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-alert",
                          {
                            attrs: {
                              outlined: "",
                              color: "warning",
                              prominent: "",
                              border: "left"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.message) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("SelectWebhookEvent", {
                  attrs: {
                    item: _vm.item.event,
                    label: "Evento*",
                    md: "12",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.webhook_event_id
                  },
                  model: {
                    value: _vm.item.webhook_event_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "webhook_event_id", $$v)
                    },
                    expression: "item.webhook_event_id"
                  }
                }),
                _c("SelectIntegration", {
                  attrs: {
                    item: _vm.item.integration,
                    error: _vm.errors.length > 0,
                    errors: _vm.errors.integration_id,
                    label: "Integração",
                    md: "4",
                    outlined: ""
                  },
                  model: {
                    value: _vm.item.integration_id,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "integration_id", $$v)
                    },
                    expression: "item.integration_id"
                  }
                }),
                _c("EmcFormTextField", {
                  attrs: {
                    label: "Url*",
                    md: "8",
                    outlined: "",
                    error: _vm.errors.length > 0,
                    "error-messages": _vm.errors.url
                  },
                  model: {
                    value: _vm.item.url,
                    callback: function($$v) {
                      _vm.$set(_vm.item, "url", $$v)
                    },
                    expression: "item.url"
                  }
                }),
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-card",
                      { attrs: { flat: "", tile: "", outlined: "" } },
                      [
                        _c(
                          "v-toolbar",
                          { attrs: { dense: "", outlined: "", flat: "" } },
                          [
                            _vm._v(
                              " Se necessário, informe opções para serem enviadas no header: "
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-3",
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.addHeader()
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v("mdi-plus")])],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-simple-table", {
                          attrs: { dense: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.item.headers, function(item, i) {
                                      return _c("tr", { key: i }, [
                                        _c(
                                          "td",
                                          [
                                            _c("EmcFormTextField", {
                                              attrs: {
                                                label: "Chave*",
                                                md: "12",
                                                outlined: "",
                                                dense: "",
                                                error: _vm.errors.length > 0,
                                                "error-messages":
                                                  _vm.errors[
                                                    "headers." + i + ".key"
                                                  ]
                                              },
                                              model: {
                                                value: item.key,
                                                callback: function($$v) {
                                                  _vm.$set(item, "key", $$v)
                                                },
                                                expression: "item.key"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("EmcFormTextField", {
                                              attrs: {
                                                label: "Valor*",
                                                md: "12",
                                                outlined: "",
                                                dense: "",
                                                error: _vm.errors.length > 0,
                                                "error-messages":
                                                  _vm.errors[
                                                    "headers." + i + ".value"
                                                  ]
                                              },
                                              model: {
                                                value: item.value,
                                                callback: function($$v) {
                                                  _vm.$set(item, "value", $$v)
                                                },
                                                expression: "item.value"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "", small: "" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeHeader(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "red"
                                                    }
                                                  },
                                                  [_vm._v("mdi-delete")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }