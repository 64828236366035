<template>
    <sys-register-base
      :loading="loading"
      title="Histórico de disparos"
      icon="mdi-webhook"
      v-bind:show.sync="dialog"
      maxWidth="90%"
      :actions="false"
      :model="model"
    >
        <template v-slot:form>
                <span>Somente histórico dos últimos 30 dias</span>
            
                <v-alert
                    v-if="message"
                    :value="true"
                    type="success"
                    dismissible
                    transition="scale-transition"
                    outlined
                    class="mt-2"
                >
                    {{ message }}
                </v-alert>
                <v-card class="mt-2">
                    <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pesquisar"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="webhook.logs"
                        :search="search"
                        :loading="loading"
                        loading-text="Carregando... Por favor, aguarde!"
                        :items-per-page="5"
                        fixed-header
                        height="350"
                    >
                    
                    <template v-slot:item.payload.id="{ item }">
                        <span class="text-no-wrap">{{ item.payload.id }}</span>
                    </template>

                    <template v-slot:item.status="{ item }">
                        <span class="text-no-wrap">{{ item.status }}</span>
                        <br/>
                        <small>{{ item.http_status }}</small>
                    </template>

                    <template v-slot:item.payload.action_type="{ item }">
                        <span class="text-no-wrap">
                            {{ item.payload.action_type }}
                        </span>
                    </template>

                    <template v-slot:item.updated_at="{ item }">
                        <span class="text-no-wrap">{{ item.updated_at }}</span>
                    </template>

                        <template v-slot:item.actions="{ item }">
                            
                            <span class="text-no-wrap">
                                <emc-table-icon 
                                    tool-tip="Clique para visualizar o payload enviado" 
                                    color="info" 
                                    icon="mdi-code-json" 
                                    @click="showPayload(item.payload)" 
                                />
                                
                                <emc-table-icon 
                                    tool-tip="Clique para reprocessar item novamente" 
                                    color="green" 
                                    icon="mdi-sync-circle" 
                                    @click="callReloadWebhook(item)"
                                    :loading="loadingReload"
                                />
                            </span>
                            
                        </template>

                        <template v-slot:item.exception="{ item }">
                            <span class="text-no-wrap" v-html="item.exception"></span>
                        </template>

                    </v-data-table>

                    <v-dialog
                        v-model="dialogPayload"
                        width="50%"
                        >

                        <v-card>
                            <v-card-title class="text-h5 grey lighten-2">
                                Payload
                            </v-card-title>
    
                            <v-card-text class="mt-2">
                                <vue-json-pretty :data="payload"></vue-json-pretty>
                            </v-card-text>
                            
                        </v-card>
                    </v-dialog>
                </v-card>
        </template>


    </sys-register-base>
</template>

<script>
import { mapActions } from 'vuex'
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    name: 'ActionsLog',
    data () {
        return {
            loading: false,
            loadingReload: false,
            message: null,
            errors: {},
            webhook: [],
            search: '',
            headers: [
                { text: 'DATA', value: 'updated_at' },
                { text: 'ID', value: 'payload.id', sortable: false },
                { text: 'URL', value: 'url', sortable: false },
                { text: 'STATUS', value: 'status', sortable: false, align: 'center' },
                { text: 'AÇÃO', value: 'payload.action_type', sortable: false, align: 'center' },
                { text: 'OPÇÕES', value: 'actions', sortable: false, align: 'center' },
                { text: 'MENSAGEM DE ERRO', value: 'exception', sortable: false },
            ],
            payload: {},
            dialogPayload: false
        }
    },
    props: {
        show: { type: Boolean, default: false },
        model: { default: null }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.errors = {}
                this.message = null
                this.$emit('update:show', value)
            }
        }
    },
    components: {
        VueJsonPretty,
    },
    watch: {
        
        show(value){

            if(value){
                this.getData();
            }

        },  
       
    },
    methods: {
        ...mapActions('webhook', ['ActionWebhooksShow', 'ActionWebhookCallReload']),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
        },
        getData()
        {

            this.webhook = [];

            if(!this.model || !this.model.id){
                return;
            }
            
            this.loading = true

            const params = {
                id: this.model.id,
                with: 'logs,event',
                column_order: 'updated_at',
                direction: 'desc'
            }

            this.ActionWebhooksShow(params)
                .then((r) => {
                    this.webhook = r.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        showPayload(item)
        {
            this.payload = item
            this.dialogPayload = true
        },
        callReloadWebhook(item){
            
            this.message = null;
            this.loadingReload = true;

            const params = {
                webhook: {
                    id: this.webhook.id,
                    url: this.webhook.url,
                    integration_id: this.webhook.integration_id,
                    institution_id: this.webhook.institution_id,
                },
                reload: item.payload
            }

            this.ActionWebhookCallReload(params)
                .then((r) => {
                    this.message = r.message
                    this.webhook = []
                    this.getData()
                })
                .finally(() => {
                    this.loadingReload = false;
                })

        }
    }
}
</script>